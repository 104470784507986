import { Component, OnInit, Renderer2, Inject, Input, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment.staging';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PatientsService } from '../../core/services/patients.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MembershipPeriod, PatientDto, ProgramType } from '@bemum/api-interfaces';

@Component({
  selector: 'bemum-pickup-point',
  templateUrl: './pickup-point.component.html',
  styleUrls: ['./pickup-point.component.scss'],
})
export class PickupPoint implements OnInit {
  openModal = false;
  selectPoint!: any;
  initialPickupState = false;
  initialPickupAdress!: any[] | undefined;
  onlyRead = false;
  isCentered = true;
  isLoading = true;
  isLoadingShip = false;
  totalOptions = 0;

  @Input() public patient: PatientDto;

  @Input() contactInfo: {
    addressLine1: string;
    city: string;
    country: string;
    phoneNumber: string;
    zipCode: string;
  };

  @Output() onPatientUpdate = new EventEmitter<PatientDto>();

  public validatePickupForm!: UntypedFormGroup;

  membershipPeriod = MembershipPeriod.MONTHLY;
  programType = ProgramType.ESSENTIAL;

  pricingTable = {
    essentialSupplements: {
      monthly: 31.5,
      three_months: 80,
      six_months: 151,
    },
    essentialCoaching: {
      monthly: 30.0,
      three_months: 76.5,
      six_months: 144,
    },
    protecteurOption: {
      monthly: 19.8,
      three_months: 50.5,
      six_months: 95,
    },
    structurantOption: {
      monthly: 16.5,
      three_months: 42,
      six_months: 80,
    },
    coachingOption: {
      monthly: 30,
      three_months: 90,
      six_months: 180,
    },
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private readonly notificationService: NzNotificationService,
    private readonly patientsService: PatientsService,
    private readonly fb: UntypedFormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    if (window.innerWidth < 500) {
      this.isCentered = false;
    }

    if (this.patient.isPickup) {
      this.initialPickupState = this.patient.isPickup;
      this.initialPickupAdress = this.patient.pickupAddress.split(',');
    }

    this.membershipPeriod = this.patient.currentMembership.membershipPeriod;
    this.programType = this.patient.currentMembership.programType;

    if (this.patient.futureMembership.essentialCoaching) {
      this.totalOptions += this.pricingTable.essentialCoaching[this.membershipPeriod];
    }
    if (this.patient.futureMembership.essentialSupplements) {
      this.totalOptions += this.pricingTable.essentialSupplements[this.membershipPeriod];
    }
    if (this.patient.futureMembership.optionCoaching) {
      this.totalOptions += this.pricingTable.coachingOption[this.membershipPeriod];
    }
    if (this.patient.futureMembership.option35) {
      this.totalOptions += this.pricingTable.protecteurOption[this.membershipPeriod];
    }
    if (this.patient.futureMembership.optionWeigth) {
      this.totalOptions += this.pricingTable.structurantOption[this.membershipPeriod];
    }

    this.injectScript();
  }

  injectScript() {
    const jqueryScript = this.loadJsScript(
      this.renderer,
      'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.js'
    );

    const colissimoScript = this.loadJsScript(
      this.renderer,
      'https://ws.colissimo.fr/widget-colissimo/js/jquery.plugin.colissimo.min.js'
    );

    const mapboxglScript = this.loadJsScript(this.renderer, 'https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.js');

    this.loadJsLink(this.renderer, 'https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css');

    jqueryScript.onload = () => {
      colissimoScript.onload = () => {
        mapboxglScript.onload = () => {
          return true;
        };
      };
    };
  }

  maMethodeDeCallBack(point: any): void {
    this.selectPoint = point;
    this.initialPickupState = false;
    this.onClose();
  }

  async getToken(): Promise<string> {
    const response = await fetch('https://ws.colissimo.fr/widget-colissimo/rest/authenticate.rest', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login: environment.COLISSIMO_LOGIN,
        password: environment.COLISSIMO_PASSWORD,
      }),
    });

    const data = await response.json();
    if (response.ok) {
      return data.token;
    }
  }

  async initializeWidget() {
    const token = await this.getToken();

    if (token) {
      const jqueryScript = this.loadJsScript(
        this.renderer,
        'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.js'
      );

      jqueryScript.onload = () => {
        const colissimoScript = this.loadJsScript(
          this.renderer,
          'https://ws.colissimo.fr/widget-colissimo/js/jquery.plugin.colissimo.min.js'
        );

        colissimoScript.onload = () => {
          const mapboxglScript = this.loadJsScript(
            this.renderer,
            'https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.js'
          );

          this.loadJsLink(this.renderer, 'https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css');

          mapboxglScript.onload = () => {
            const url_serveur = 'https://ws.colissimo.fr';
            (window as any).maMethodeDeCallBack = this.maMethodeDeCallBack.bind(this);

            (window as any).$('#monIdDeWidgetColissimo').frameColissimoOpen({
              URLColissimo: url_serveur,
              callBackFrame: 'maMethodeDeCallBack',
              ceCountry: this.patient.country === 'France' ? 'FR' : this.patient.country === 'Belgique' ? 'BE' : 'LU',
              ceAddress: this.patient.addressLine1,
              ceZipCode: this.patient.zipCode,
              ceTown: this.patient.city,
              token: token,
            });

            this.isLoading = false;

            if (document.getElementsByClassName('widget_colissimo_close')) {
              document.getElementsByClassName('widget_colissimo_close')[0].remove();
            }
          };
        };
      };
    }
  }

  loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    renderer.appendChild(this.document.head, script);
    return script;
  }

  loadJsLink(renderer: Renderer2, src: string): HTMLLinkElement {
    const link = renderer.createElement('link');
    link.rel = 'stylesheet';
    link.href = src;
    renderer.appendChild(this.document.head, link);
    return link;
  }

  showModal() {
    this.injectScript();
    this.openModal = true;
    this.isLoading = true;
  }

  onClose() {
    this.openModal = false;
    this.injectScript();
  }

  restorePickupState() {
    this.selectPoint = '';
    if (this.patient.isPickup) {
      this.initialPickupState = true;
      this.initialPickupAdress = this.patient.pickupAddress.split(',');
    } else {
      this.initialPickupState = false;
    }
  }

  updateShipping() {
    const data = {
      isPickup: true,
      pickupId: this.selectPoint.identifiant,
      pickupAddress: `${this.selectPoint.nom},${this.selectPoint.adresse1},${this.selectPoint.codePostal},${this.selectPoint.localite},${this.selectPoint.libellePays}`,
    };

    this.patientsService.update(this.patient?.id, data).subscribe(
      (patient) => {
        this.patient = patient;
        localStorage.setItem('patient', JSON.stringify(patient));

        this.restorePickupState();

        this.onPatientUpdate.emit(patient);
        // success notification
        this.notificationService.success('Modification terminée', 'Les modifications ont bien été enregistrées');
        this.updateSubscriptionFees();
      },
      () =>
        this.notificationService.error(
          'Modification impossible',
          'Impossible de modifier les informations demandées, veuillez retenter plus tard.'
        )
    );
  }

  homeDelivery() {
    this.isLoadingShip = true;
    const data = {
      isPickup: false,
      pickupId: null,
      pickupAddress: null,
    };
    this.patientsService.update(this.patient?.id, data).subscribe({
      next: (patient) => {
        this.patient = patient;
        localStorage.setItem('patient', JSON.stringify(patient));

        this.restorePickupState();

        // success notification
        this.notificationService.success('Modification terminée', 'Les modifications ont bien été enregistrées');
        this.isLoadingShip = false;
        this.updateSubscriptionFees();
      },
      error: (e) =>
        this.notificationService.error(
          'Modification impossible',
          'Impossible de modifier les informations demandées, veuillez retenter plus tard.'
        ),
    });
  }

  updateSubscriptionFees() {
    const options = {
      isPickup: this.initialPickupState,
    };

    if (
      (this.patient.futureMembership.membershipPeriod === 'three_months' &&
        this.patient.futureMembership.programType === 'age') ||
      (this.membershipPeriod === 'three_months' && this.programType === 'sopk') ||
      this.totalOptions >= 0
    ) {
      return;
    }

    if (this.patient?.stripeCustomerId) {
      this.patientsService.updateShippingFees(this.patient.id, options).subscribe({
        next: () => {},
        error: () => {},
      });
    }
  }
}
