import {
  ApiProperty,
  IsBoolean,
  IsDefined,
  IsEmail,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
  Type,
} from './decorators-nest';
import { CreateUtmDto } from './utm.interface';

export enum MembershipPeriod {
  MONTHLY = 'monthly',
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
}

export enum ProgramType {
  ESSENTIAL = 'essentiel',
  AGE = 'age',
  SOPK = 'sopk',
  COMPLET = 'complet',
  SHOP = 'shop',
}

export enum DeliveryProductDto {
  ESSENTIAL_SUPPLEMENTS = 'Compléments Essentiels',
  ESSENTIAL_SUPPLEMENTS_3_MONTHS = 'Compléments Essentiels 3 mois',
  ESSENTIAL_SUPPLEMENTS_6_MONTHS = 'Compléments Essentiels 6 mois',
  PROTECTEUR = 'Spécificité + 35 ans',
  PROTECTEUR_3_MONTHS = 'Spécificité + 35 ans 3 mois',
  PROTECTEUR_6_MONTHS = 'Spécificité + 35 ans 6 mois',
  STRUCTURANT = 'Spécificité poids / SOPK',
  STRUCTURANT_3_MONTHS = 'Spécificité poids / SOPK 3 mois',
  STRUCTURANT_6_MONTHS = 'Spécificité poids / SOPK 6 mois',
  SHOP_ESSENTIAL_SUPPLEMENT = 'E + / Fertilité',
  SHOP_OMEGA_SUPPLEMENT = 'Omega',
  SHOP_PROTECTEUR = 'P + / Protecteur',
  SHOP_STRUCTURANT = 'S + / Structurant',
  SHOP_COACHING = 'Coaching',
}

export class ProductSelectionDto {
  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielCoaching: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielSupplements: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielSupplement: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withOmegaSupplement: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withCoachingOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withStructurantOption: boolean;
  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withProtecteurOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  productQuantities: { [productId: string]: number }; // Map product ID to quantities
}

export class CreateStripeCustomerAndCheckoutSessioDto {
  @IsEmail()
  @ApiProperty({ description: 'Email du patient', example: 'patient@email.com' })
  email: string;

  @IsString()
  @ApiProperty({ description: 'Prénom', example: 'Jane' })
  firstname: string;

  @IsString()
  @ApiProperty({ description: 'Nom de famille', example: 'Doe' })
  lastname: string;

  @IsString()
  @ApiProperty({ description: 'Adresse du patient - ligne 1' })
  addressLine1: string;

  @IsString()
  @ApiProperty({ description: 'Adresse du patient - ligne 2', required: false })
  addressLine2: string;

  @IsString()
  @ApiProperty({ description: 'Code postal du patient' })
  zipCode: string;

  @IsString()
  @ApiProperty({ description: 'Ville du patient' })
  city: string;

  @IsString()
  @ApiProperty({ description: 'Pays du patient' })
  country: string;

  @IsString()
  @ApiProperty({ description: 'Numéro de téléphone du patient' })
  phoneNumber: string;

  @IsString()
  @IsOptional()
  @ApiProperty({ description: 'Source du patient', example: 'Search Engine' })
  source?: string;

  @ValidateNested()
  @Type(() => ProductSelectionDto)
  @IsDefined()
  @ApiProperty({
    description: 'Product selection with quantities and options',
    example: {
      withOmegaSupplement: true,
      withEssentialSupplement: true,
      productQuantities: {
        omegaSupplement: 1,
        essentialSupplement: 2,
      },
    },
  })
  productSelection: ProductSelectionDto;

  @IsBoolean()
  @ApiProperty({ description: 'Indique si la livraison est activée' })
  withShipping: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ enum: MembershipPeriod })
  @IsEnum(MembershipPeriod)
  membershipPeriod: MembershipPeriod;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ enum: ProgramType })
  @IsEnum(ProgramType)
  programType: ProgramType;

  @IsString()
  @ApiProperty()
  domainURL: string;

  @IsOptional()
  @IsString()
  @ApiProperty({ description: 'Code promotionnel utilisé', required: false })
  promotionCodeId?: string;

  @ValidateNested()
  @Type(() => CreateUtmDto)
  @IsOptional()
  @ApiProperty({ description: 'Paramètres UTM pour le suivi marketing' })
  utm?: CreateUtmDto;

  @ApiProperty({
    description: 'Enable/Disable pickup point',
    required: false,
  })
  @IsOptional()
  @IsBoolean()
  isPickup?: boolean;

  @IsOptional()
  @IsString()
  @ApiProperty({ description: 'Identifiant du point relais', required: false })
  pickupId?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({ description: 'Adresse du point relais', required: false })
  pickupAddress?: string;
}

export class CreateCheckoutSessionDto extends CreateStripeCustomerAndCheckoutSessioDto {}

export class CreateStripeCheckoutSessionDto {
  @ValidateNested()
  @Type(() => ProductSelectionDto)
  @ApiProperty()
  productSelection: ProductSelectionDto;

  @ApiProperty({
    description: 'Enable/Disable pickup point',
    required: false,
  })
  @IsOptional()
  @IsBoolean()
  isPickup?: boolean;

  @IsNotEmpty()
  @IsString()
  @ApiProperty()
  domainURL: string;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withShipping: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ description: 'Type of the program', enum: ProgramType })
  @IsEnum(ProgramType)
  programType: ProgramType;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ enum: MembershipPeriod })
  @IsEnum(MembershipPeriod)
  membershipPeriod: MembershipPeriod;
}

export class CheckoutSession {
  @IsString()
  @ApiProperty({ description: 'URL de la session de checkout' })
  url: string;
}

export class DeleteCustomerRequestDto {
  @IsEmail()
  @ApiProperty({ description: 'Email du client à supprimer', example: 'client@email.com' })
  email: string;
}

export class StripeWebhookDto {
  @IsNotEmpty()
  @IsString()
  @ApiProperty()
  id: string;

  @IsNotEmpty()
  @IsString()
  @ApiProperty()
  object: string;

  @IsNotEmpty()
  @IsString()
  @ApiProperty()
  api_version: string;

  @IsNumber()
  @ApiProperty()
  created: number;

  @IsDefined()
  @ApiProperty()
  data: object;

  @IsDefined()
  @ApiProperty()
  livemode: boolean;

  @IsNumber()
  @ApiProperty()
  pending_webhooks: number;

  @IsDefined()
  @ApiProperty()
  request: object;

  @IsString()
  @ApiProperty()
  type: string;
}

export class CreateSubscriptionRequestDto {
  @IsBoolean()
  @ApiProperty()
  withShipping: boolean;

  @ValidateNested()
  @Type(() => ProductSelectionDto)
  @ApiProperty()
  productSelection: ProductSelectionDto;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ enum: MembershipPeriod })
  @IsEnum(MembershipPeriod)
  membershipPeriod: MembershipPeriod;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ enum: ProgramType })
  @IsEnum(ProgramType)
  programType: ProgramType;
}

export class UpdateSubscriptionRequestDto {
  @IsBoolean()
  @ApiProperty()
  isPickup: boolean;

  @IsBoolean()
  @ApiProperty()
  withShipping: boolean;

  @ValidateNested()
  @Type(() => ProductSelectionDto)
  @ApiProperty()
  productSelection: ProductSelectionDto;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ enum: MembershipPeriod })
  @IsEnum(MembershipPeriod)
  membershipPeriod: MembershipPeriod;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ enum: ProgramType })
  @IsEnum(ProgramType)
  programType: ProgramType;
}

export class UpdateShippingFeesDto {
  @IsBoolean()
  @ApiProperty()
  isPickup: boolean;
}

/** Stripe Customer Portal Session */
export interface Session {
  url: string;
}
